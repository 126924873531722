<template>
  <div class="block">
    <div class="AreaR" style="width: auto">
      <div class="article_content">
        <div class="title">商家入驻</div>
        <div class="add_time">商家服务热线：010-62456788</div>
        <div
          class="sett-a-item"
          style="
            padding: 0px;
            margin: 0px;
            color: rgb(85, 85, 85);
            font-family: 'Microsoft YaHei', tahoma, arial, Hevetica, '\\5b8b体',
              sans-serif;
          "
        >
          <div
            class="sett-cont"
            style="
              padding: 40px 36px;
              margin: 0px;
              font-size: 14px;
              color: rgb(140, 140, 140);
              line-height: 30px;
            "
          >
            <div class="temp" style="padding: 0px; margin: 0px">
              <div class="mt" style="padding: 0px; margin: 0px">
                <h3
                  style="
                    padding: 0px;
                    margin: 0px;
                    font-size: 14px;
                    font-family: 'microsoft yahei';
                    font-weight: normal;
                    text-align: left;
                  "
                >
                  线下入驻流程
                </h3>
              </div>
              <div class="mc" style="padding: 0px; margin: 0px">
                <div class="steps" style="padding: 0px; margin: 0px">
                  <div class="s-title" style="padding: 0px; margin: 0px">
                    1&nbsp;申请入驻 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                  <div class="s-con" style="padding: 0px; margin: 0px">
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >核对资质信息：</strong
                      >本网首先关注电商入驻标准，确认符合要求
                    </p>
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >提交入驻申请：</strong
                      >商家务必按照邮件要求将信息发送到各类目负责人邮箱
                    </p>
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >双方沟通：</strong
                      >收到资料后，本网招商负责人会第一时间与您取得联系
                    </p>
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >确认合作意向：</strong
                      >招商负责人会与您就合作条款、要求进行沟通，双方确认合作意向
                    </p>
                  </div>
                </div>
                <div class="steps" style="padding: 0px; margin: 0px">
                  <div class="s-title" style="padding: 0px; margin: 0px">
                    2&nbsp;签订合同 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                  <div class="s-con" style="padding: 30px 0px 0px; margin: 0px">
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >发送合同：</strong
                      >招商负责人邮寄合同到商家处
                    </p>
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >资质合同审核：</strong
                      >商家按照要求签订合同、提交资质文件到本网
                    </p>
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >资质合同审核：</strong
                      >本网相关部门审核合同与资质文件
                    </p>
                  </div>
                </div>
                <div class="steps last" style="padding: 0px; margin: 0px">
                  <div class="s-title" style="padding: 0px; margin: 0px">
                    3&nbsp;开店准备 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                  <div class="s-con" style="padding: 30px 0px 0px; margin: 0px">
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >准备产品资料：</strong
                      >商家准备需要合作产品的相关图片及说明
                    </p>
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >交纳相关费用：</strong
                      >商家交纳保证金和平台使用费
                    </p>
                    <p style="padding: 0px; margin: 0px">
                      <strong style="padding: 0px; margin: 0px"
                        >开店销售：</strong
                      >产品运营经理将与您取得联系安排开店事宜
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="temp" style="padding: 0px; margin: 0px">
              <div class="mt" style="padding: 0px; margin: 0px">
                <h3
                  style="
                    padding: 0px;
                    margin: 0px;
                    font-size: 14px;
                    font-family: 'microsoft yahei';
                    font-weight: normal;
                    text-align: left;
                  "
                >
                  入驻指南
                </h3>
              </div>
              <div class="mc guige" style="padding: 0px; margin: 0px">
                <dl style="padding: 0px; margin: 0px">
                  <dt style="padding: 0px; margin: 0px">资金要求：</dt>
                  <dd style="padding: 0px; margin: 0px">
                    公司注册资金50万及50万以上人民币；
                  </dd>
                </dl>
                <dl style="padding: 0px; margin: 0px">
                  <dt style="padding: 0px; margin: 0px">授权资质：</dt>
                  <dd style="padding: 0px; margin: 0px">
                    需要确保授权链条的完整，即申请入驻企业拿到的授权能够逐级逆推回品牌商；
                  </dd>
                </dl>
                <dl style="padding: 0px; margin: 0px">
                  <dt style="padding: 0px; margin: 0px">提供发票：</dt>
                  <dd style="padding: 0px; margin: 0px">
                    所有入驻的企业必须给消费者提供正规发票，发票盖章的公司名称必须与商家与商创合作的公司名称一致；
                  </dd>
                </dl>
                <dl style="padding: 0px; margin: 0px">
                  <dt style="padding: 0px; margin: 0px">申请类型：</dt>
                  <dd style="padding: 0px; margin: 0px">
                    本网暂不接受未取得国家商标总局颁发的商标注册证或商标受理通知书的境外品牌的开店申请；
                  </dd>
                </dl>
                <dl style="padding: 0px; margin: 0px">
                  <dt style="padding: 0px; margin: 0px">真实有效：</dt>
                  <dd style="padding: 0px; margin: 0px">
                    请务必保证您入驻前后提供的相关资质的真实有效性（若您提供的相关资质为第三方提供，如商标注册证、授权书等，请务必先行核实其真实有效性），一旦发现虚假资质，您的公司将被列入非诚信商家名单，本网城将不再与您进行合作，并将扣除保证金。
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <p>&nbsp;</p>
      </div>
      <div class="clear10"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.block {
  width: 1200px;
  height: auto;
  margin: auto;
  padding-top: 10px;
  .AreaR {
    float: left;
    .article_content {
      border: 1px solid #ededed;
      padding: 20px;
      background: #fff;
      font-size: 14px;
      line-height: 30px;
      .title {
        text-align: center;
        font-size: 36px;
        color: #333;
        line-height: 55px;
      }
      .add_time {
        border-bottom: 1px solid #ddd;
        padding-bottom: 6px;
        text-align: center;
        margin-bottom: 15px;
      }
      h3 {
        height: 30px;
        background: url( ~@/assets/imgs/icp/sort_b.gif)  left top repeat-x;
        line-height: 30px;
        text-align: right;
        padding-right: 5px;
      }
    }
  }
}
</style>